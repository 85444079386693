<template>
  <div class="d-flex justify-content-center pt-5">
    <a-result
      status="success"
      title="Successfully verified your account"
      sub-title="You have been completed the verification of your email"
    >
      <!-- <template #extra>
        <a-button key="console" type="primary"> Go Console </a-button>
        <a-button key="buy"> Buy Again </a-button>
      </template> -->
    </a-result>
  </div>
</template>

<script>
export default {
  mounted() {
    const payload = {
      id: this.user.id,
      role: this.role,
    }
    this.$store.dispatch('user/VERIFY_EMAIL', { payload })
  },
  computed: {
    user() {
      return this.$store.state.user.user
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
  },
}
</script>

<style>
</style>
